.BackgroundImage {
  width: 100vw;
  height: 100vh;
}

.ImageStyle {
  object-fit: cover;  
  object-position: 10% 0;
  width: 100%;
  height: 100%;
}

.BackgroundImage h1 {
  color: white;
  font-size: 150px;
  font-weight: bold;
  font-family: 'Alegreya Sans SC', sans-serif;
  text-align: center;
  position: relative;
  top: -375px;
}

@media only screen and (max-width: 1500px) {
  .BackgroundImage h1 {
    font-size: 100px;
    top: -50vh;
  }
}

@media only screen and (max-width: 500px) {
  .BackgroundImage h1 {
    font-size: 60px;
    top: -50vh;
  }
}


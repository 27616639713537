.AppHeaderContainer {
    position: -webkit-sticky;
    /* Safari */
    position: fixed;
    top: 0;
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    z-index: 1;
    font-size: 40px;
}

.AppHeaderContainerShadow {
    box-shadow: 0px 1px 5px #888888;
    background: white;
}

.AppHeaderContainer a:hover {
    color: #626665;
}
.AppHeaderContainer h5 {
    font-family: 'Raleway', sans-serif;
}

.AppHeaderContainer h2  {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}

.AppHeaderContainer a {
    text-decoration: none;
    color: inherit;
    padding-left: 10px;
    padding-right: 10px;
}

.hamburger {
    width: 200px;
    text-align: center;
    position: absolute;
    right: 0;
    background: white;
    box-shadow: -5px 10px 10px -10px #888888;
}

.collection {
    display: flex;
    align-items: center;
}

.collection>* {
    margin-left: 2vw;
    margin-right: 2vw;
}

@media only screen and (max-width: 400px) {
    .hamburger {
        width: 100%;
        box-shadow: 0px 10px 10px -10px #888888;
    }
    .AppHeaderContainer {
        justify-content: space-around;
    }

    .AppHeaderContainer h2  {
        font-size: 24px;
    }
}